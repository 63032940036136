import { RingFinishEnum } from '../enums/RingFinishEnum';
import { RingModelEnum } from '../enums/RingModelEnum';

export const BUSINESS_PATHNAME = '/business';
export const POP_PATHNAME = '/product/rings';
export const WHY_OURA_PATHNAME = '/why-oura';
export const MEMBERSHIP = '/membership';
export const CONTACT_PATHNAME = '/contact';
export const ACCESSORIES_ROOT_PATH = '/product/accessories';
export const GEN3_POP_PATHNAME = `${POP_PATHNAME}/${RingModelEnum.Gen3}`;
export const OURA_RING_4_WIZARD_SUBPATH = `${RingModelEnum.Oura4}/${RingFinishEnum.Silver}`;
