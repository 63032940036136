import NextImage, { type ImageLoader } from 'next/image';
import { useTranslations } from 'next-intl';
import type { Ref } from 'react';
import { forwardRef } from 'react';

import { getLegacyEnvVariable } from '@/app/components/LegacyEnvVariables/util';

import { imgixLoader } from './loaders';
import type { ImageProps } from './types';
import { getSrcUrl } from './utils';

const Image = forwardRef(function Image(
  {
    alt,
    format = 'jpg',
    loader,
    localizedAlt,
    localizedSrc,
    src,
    ...props
  }: ImageProps,
  ref?: Ref<HTMLImageElement>,
): JSX.Element {
  const t = useTranslations();
  const _alt = localizedAlt ? t(localizedAlt) : (alt ?? '');
  const _src = localizedSrc ? t(localizedSrc) : (src ?? '');

  if (!_src) {
    throw new Error('No src provided for Image component.');
  }

  const srcUrl = getSrcUrl(_src, format);
  // We need to set a loader via props for Jest because the custom loader
  // set in the Next config currently is not used due to a bug in next/jest.
  const _loader: ImageLoader | undefined =
    getLegacyEnvVariable('NODE_ENV') === 'test'
      ? (img) => imgixLoader(img)
      : loader;

  return (
    <NextImage alt={_alt} loader={_loader} ref={ref} src={srcUrl} {...props} />
  );
});

export default Image;
