import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import type { Variables } from 'graphql-request';
import { gql, request } from 'graphql-request';

import { getGraphQlEndpoint } from '@/api/backendAPI';
import { getCartIdCookie, saveCartIdCookie } from '@/utils/cartUtils';

import type {
  RefreshCart,
  RefreshCart_cart_refreshCart_cart,
  RefreshCartVariables,
} from './types/RefreshCart';

export const query = gql`
  query RefreshCart($cartId: String) {
    cart(cartId: $cartId) {
      refreshCart {
        resultCategory
        cartId
        cartIdChangeReason
        cart {
          lastUpdatedAt
          promotions {
            promotionCode
            campaign
          }
          lineItems {
            skuCode
            quantity
            sku {
              bbySkuId
            }
          }
          isWarrantyReplacement
          itemCountForIcon
          maxRingQuantity
          maxChargerQuantity
          ringCount
          chargerCount
          digitalSized
          creationTags {
            vendorId
          }
        }
      }
    }
  }
`;

export function withQueryKey(vars: RefreshCartVariables) {
  return {
    queryKey: ['refreshCart', vars.cartId],
    queryFn: async () => {
      const url = getGraphQlEndpoint()!;
      const data: RefreshCart | undefined = await request(
        url,
        query,
        vars as Variables,
      );

      const refreshResponse = data?.cart?.refreshCart;

      if (
        refreshResponse?.resultCategory === 'success' &&
        refreshResponse.cartId
      ) {
        saveCartIdCookie(refreshResponse.cartId);
      }

      return refreshResponse?.cart;
    },
  };
}

export function useRefreshCart(): UseQueryResult<
  RefreshCart_cart_refreshCart_cart | null | undefined,
  Error
> {
  const cartId = getCartIdCookie();
  return useQuery({
    ...withQueryKey({ cartId }),
    // Keep data cached for 5 minutes
    gcTime: 5 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
  });
}
